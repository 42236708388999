.carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #ffffff;
    padding: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.carousel-inner {
    display: flex;
    gap: 10px;
    will-change: transform;
}

.carousel-item {
    flex: 0 0 auto;
    width: 200px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.carousel-item:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .carousel-item {
        width: 150px;
        height: 200px;
    }
}