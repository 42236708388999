/* General styling for the container */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.slideshow-image {
    width: 90%;
    height: auto;
    border-radius: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease-in-out;
}

/* Hover effect for images */
.slideshow-image:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .slideshow-image {
        width: 100%;
        border-radius: 15px;
    }

    .container {
        margin-top: 71px;
    }

    .image-container {
        width: 90%;
    }

    .slideshow-controls {
        height: auto;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .slideshow-controls div {
        flex: 1;
        text-align: center;
        margin: 5px 0;
        font-size: 14px;
    }
}

/* Styling for the slideshow controls */
.slideshow-controls {
    display: flex;
    height: 90px;
    width: 70%;
    background-color: rgba(245, 245, 245, 0.8);
    border-radius: 20px;
    margin-top: -3.5%;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.slideshow-controls div {
    cursor: pointer;
    transition: color 0.3s ease;
}

/* Hover effect for controls */
.slideshow-controls div:hover {
    color: #007BFF;
}

@media (max-width: 768px) {
    .slideshow-controls {
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        padding: 10px 0;
        border-radius: 10px;
    }

    .slideshow-controls div {
        flex: 1;
        text-align: center;
        font-size: 7px;
    }
}