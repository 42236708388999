.diamond-info-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
    padding: 10px;
}

.diamond-image-container {
    width: 350px;
    height: 350px;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 20px;
}

.diamond-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.diamond-details {
    max-width: 900px;
    text-align: center;
}

.diamond-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.diamond-description {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 500px;
}

.diamond-specs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.spec {
    margin: 5px 10px;
    font-size: 14px;
}

.learn-more-button {
    padding: 10px 20px;
    background-color: transparent;
    color: #000000;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 2px solid black;
}

.learn-more-button:hover {
    border-radius: 50px;
}