.intro-container {
    text-align: center;
    animation: fadeInUp 1s ease-out;
    font-family: EB Garamond;
}

.introtext {
    font-family: EB Garamond;
    margin-top: 0px;
    padding-top: 30px;
}

.intro-text {
    font-family: EB Garamond;
}

.bgimagess {
    background-image: url("../../image/DSC06126.JPG");
    background-size: cover;
    width: 350px;
    height: 416px;
    border-radius: 10px 10px 10px 150px;
}

/* .bgimagess img {
    height: 200px;
    max-width: 300px;
    object-fit: cover;
    position: relative;
    top: 170px;
    left: 200px;
    border-radius: 10px 100px 10px 10px;
}

@media (max-width: 768px) {
    .bgimagess img {
        top: 300px;
        left: 0;
    }
} */
.bgimagess img {
    height: 200px;
    width: 300px;
    object-fit: cover;
    position: relative;
    top: 170px;
    left: 200px;
    border-radius: 10px 100px 10px 10px;
}

@media (max-width: 768px) {
    .bgimagess img {
        top: 300px;
        left: 20px;
        width: 217px;
        height: 150px;
    }
}

@media (max-width: 768px) {
    .mainintro {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .introdetails {
        margin-top: 70px;
    }

    .content-containers {
        margin-top: 30px;
    }
}

.introdetails {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 21.78px; */
    letter-spacing: 0.2em;
    text-align: left;
    /* color: #ffffff; */
}

.intros {
    font-family: EB Garamond;
    font-size: 40px;
    font-weight: 500;
    /* line-height: 62.64px; */
    text-align: left;
    /* color: #ffffff; */
    /* padding-top: 30px; */

}

.introductions {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 30px; */
    text-align: left;
    max-width: 450px;
    /* color: #ffffff; */
}

.content-containers {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    min-height: 500px;
}

.maincomponent {
    /* background-image: url("./image/9.JPG"); */
    /* background-color: #f5f5f5;    */
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    /* min-height: 550px; */
    margin-top: 50px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Adjust the alpha value for opacity */
}