.cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
    background-image: url("../../image/logo-removebg-preview.png");
    /* Set your logo image */
    background-size: cover;
    /* Ensure the image covers the entire area */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    border-radius: 50%;
    pointer-events: none;
    z-index: 9999;
    /* Ensure it's above other elements */
    transition: transform 0.15s ease-out;
    /* Smooth transition for movement */
}

/* Add animation effect */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.7;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 0.3;
    }
}