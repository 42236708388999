.detail {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-wrap: wrap;
}

.detail-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    animation: fadeInUp 1s ease-in-out;
    margin: 10px 0;
}

.detail-divider {
    height: 25px;
    width: 1.5px;
    background-color: blue;
    animation: fadeIn 1s ease-in-out;
    display: none;
    /* Hide dividers by default */
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .detail {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0px;
    }

    .detail-item {
        width: 100%;
        justify-content: center;
    }

    .detail-divider {
        /* display: block; */
        /* Show dividers in mobile view */
        width: 100%;
        height: 1.5px;
        margin: 10px 0;
    }
}