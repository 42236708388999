/* Updated styles for a cleaner design */
.letestcollection {
    font-family: 'EB Garamond', serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 20px;
}

.tredingjwell {
    font-family: 'EB Garamond', serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 40px;
}

.silverbracelets {
    font-family: 'EB Garamond', serif;
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 10px;
}

.productdetail {
    max-width: 300px;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.detailbutton {
    height: 40px;
    width: 120px;
    background-color: #333;
    color: white;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.detailbutton:hover {
    background-color: #555;
}

.detailset {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
    gap: 20px;
}

.detailsets {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
    gap: 20px;
}

.detailset img {
    max-height: 400px;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.detailsets img {
    max-height: 400px;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .detailset img {
        max-height: 400px;
        max-width: 370px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .detailsets img {
        max-height: 400px;
        max-width: 370px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .detailsets {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 40px;
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: column-reverse;
    }
}