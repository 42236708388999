.maintypeset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.typebyset {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    background-color: rgb(233, 233, 233);
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.typebyset b {
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.typebyset .selected {
    color: #007BFF;
    font-weight: bold;
}

.typebyset b:hover {
    transform: scale(1.1);
}

.category-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
}

.product-card {
    position: relative;
    width: 250px;
    margin: 20px;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.product-card:hover img {
    transform: scale(1.1);
}

.product-card .info {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    padding: 10px;
    transition: bottom 0.3s ease;
}

.product-card .shop-button {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    opacity: 0;
}

.product-card:hover .shop-button {
    display: block;
    opacity: 1;
}

.product-card .price-card {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    /* padding: 10px; */
}

@media (max-width: 768px) {
    .typebyset {
        /* flex-direction: column; */
    }

    .product-card {
        width: 45%;
    }

    .product-card img {
        height: 150px;
    }

    .typebyset b {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .product-card {
        width: 90%;
    }

    .product-card img {
        height: 120px;
    }
}