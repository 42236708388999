.buy img {
    height: 50px;
}

.buy h2 {
    font-family: EB Garamond;
}

.buyh1 {
    font-family: EB Garamond;
    text-align: center;
    margin-top: 50px;
}

.buy p {
    width: 250px;
}

.buy {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}