/* General styling for the product container */
.product-container {
    margin-top: 50px;
    padding: 0 20px;
}

.product-grid {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
}

.product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 200px; */
    transition: transform 0.3s ease-in-out;
}

.product-item img {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 5px solid grey;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.product-item img:hover {
    transform: scale(1.05);
}

.product-collection {
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
    font-family: 'EB Garamond', serif;
}

/* Responsive design */
@media (max-width: 768px) {
    /* .product-grid {
        flex-direction: column;
    } */

    /* .product-item {
        width: 100%;
    } */

    .product-item img {
        height: 110px;
        width: 110px;
    }
}