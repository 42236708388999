/* index.css */

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    /* Add a slight opacity */
    backdrop-filter: blur(5px);
    /* Add a blur effect */
    z-index: 1000;
}

.nav {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
}

.nav img {
    height: 60px;
    width: auto;
}

nav {
    margin-left: auto;
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

nav ul li {
    margin-right: 20px;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    position: relative;
    transition: color 0.3s ease;
    font-weight: bold;
}

/* Border animation */
nav ul li a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    /* Initially transparent */
    transition: background-color 0.3s ease;
    /* Smooth transition */
    font-weight: bold;
}

nav ul li a:hover::before {
    background-color: #666;
    /* Change to desired color on hover */
}

/* Other animation */
nav ul li a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    /* Initially transparent */
    transform: scaleX(0);
    /* Start with no width */
    transform-origin: right;
    /* Expand from right to left */
    transition: transform 0.3s ease;
    /* Smooth transition */
}

nav ul li a:hover::after {
    transform: scaleX(1);
    /* Expand to full width on hover */
    transform-origin: left;
    /* Expand from left to right */
}

.icon {
    display: none;
}

.icon2 {
    display: none;
}

@media only screen and (max-width: 768px) {
    .icon {
        display: block;
        position: absolute;
        left: 20px;
    }

    .icon2 {
        display: block;
        position: absolute;
        right: 20px;
    }

    .nav {
        transition: 0.7s ease-in-out;
        justify-content: space-evenly;
    }

    nav ul {
        transition: 0.7s ease-in-out;
        margin-top: 50px;
        flex-direction: column;
    }

    nav ul li {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .sidebar {
        display: none;
        /* Initially hide sidebar */
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        height: 100%;
        background-color: #fff;
        z-index: 1000;
        overflow-x: hidden;
        padding-top: 20px;
        transition: 0.7s ease-in-out;
    }

    .sidebar.show {
        transition: 0.7s ease-in-out;
        display: block;
    }
}