.footer {
    background-color: #111;
    color: #fff;
    padding: 40px 20px;
    font-family: 'EB Garamond', serif;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
}

.footer .footer-section {
    flex: 1;
    margin: 20px;
    min-width: 200px;
}

.footer .footer-section h3 {
    font-size: 20px;
    margin-bottom: 20px;
    position: relative;
}

.footer .footer-section h3::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background: #fff;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
}

.footer .footer-section p,
.footer .footer-section a {
    font-size: 16px;
    color: #ccc;
    text-decoration: none;
    margin: 8px 0;
}

.footer .footer-section a:hover {
    color: #fff;
}

.footer .footer-socials {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.footer .footer-socials a {
    color: #ccc;
    font-size: 20px;
    transition: color 0.3s;
}

.footer .footer-socials a:hover {
    color: #fff;
}

.footer-bottom {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #333;
    margin-top: 20px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer .footer-section {
        text-align: center;
        margin: 10px 0;
    }
}