.intro-container {
    text-align: center;
    animation: fadeInUp 1s ease-out;
    font-family: EB Garamond;
}

.introtext {
    font-family: EB Garamond;
    margin-top: 0px;
    padding-top: 30px;
    text-align: center;
}

.intro-text {
    font-family: EB Garamond;
    text-align: center;
}

.bgimages {
    background-image: url("../../image/DSC05569.JPG");
    background-size: cover;
    background-size: cover;
    width: 350px;
    height: 416px;
    border-radius: 10px 10px 10px 150px;
}

.bgimages img {
    height: 200px;
    width: 300px;
    object-fit: cover;
    position: relative;
    top: 170px;
    left: 200px;
    border-radius: 10px 100px 10px 10px;
}

@media (max-width: 768px) {
    .bgimages img {
        top: 300px;
        left: 20px;
        width: 217px;
        height: 150px;
    }
}

@media (max-width: 768px) {
    .mainintroduction {
        margin-top: 30px;
    }
}

@media (max-width: 768px) {
    .introdetail {
        margin-top: 50px;
    }
}

.introdetail {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 21.78px; */
    letter-spacing: 0.2em;
    text-align: left;
}

.intro {
    font-family: EB Garamond;
    font-size: 48px;
    font-weight: 500;
    /* line-height: 62.64px; */
    text-align: left;
}

.introduction {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    max-width: 450px;
}

.content-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    flex-wrap: wrap;
    width: 100%;
}