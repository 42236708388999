/* styles.css */

.map-container {
    height: 400px;
    /* Default height */
    width: 40vw;
    /* Default width */
    margin-bottom: 10px;
    border-radius: 20px;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .map-container {
        width: 90vw;
        /* Adjust width for mobile devices */
    }
}